





















































































import {
  Component,
  Vue,
  Prop,
  ModelSync,
  Ref,
  Watch,
} from 'vue-property-decorator'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { uuid } from '@/utils/util'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { dailyPlanDetail } from '@/api/dailyPlan'

const defaultForm = {
  time: [],
  workPlan: '',
  indicatorRecords: [
    {
      uid: Date.now(),
      indicatorType: undefined,
      indicatorDescription: '',
    },
  ],
  businessAttachmentList: [],
}
@Component({
  components: { DatePicker, Commit, ThumbsUp },
})
export default class PlanInfoDetail extends Vue {
  @Prop({ type: String, default: '', required: true })
  planId!: string
  moment = moment
  get currentDateStr() {
    const week = ['日', '一', '二', '三', '四', '五', '六']
    return (
      this.formData.planDate +
      ' ' +
      '周' +
      week[new Date(this.formData.planDate).getDay()]
      //this.currentDate.format('YYYY年M月DD日  周')
    )
  }

  get previewWorkPlan() {
    return this.formData.workPlan.replace(/\n/g, '<br/>')
  }
  dataLoading: boolean = true
  formData: any = { ...cloneDeep(defaultForm) }
  formRule = {
    workPlan: [
      { required: true, message: '请填写工作计划内容', trigger: 'blur' },
      {
        min: 1,
        max: 500,
        message: '工作计划内容应为1到500个字符',
        trigger: 'blur',
      },
    ],
  }

  async getDetailById(id: string) {
    this.dataLoading = true
    const [err, res] = await dailyPlanDetail(id)
    if (err) {
      this.dataLoading = false
      return
    }
    const {
      startTime,
      endTime,
      indicatorRecords = [],
      businessAttachmentList = [],
    } = res
    this.formData = {
      ...res,
      time: [startTime, endTime],
      indicatorRecords: indicatorRecords.map((item: any) => ({
        ...item,
        uid: uuid(),
      })),
      businessAttachmentList,
    }
    this.dataLoading = false
  }
  mounted() {
    this.getDetailById(this.planId)
  }
}
