




































import { Component, Vue,Prop } from 'vue-property-decorator'
import moment from 'moment'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
import Share from '@/components/Share/index.vue'
import { getYearPlanDetailById } from '@/api/year'
@Component({
  components: {
    Commit,
    ThumbsUp,
    Share,
  },
})
export default class YearDetail extends Vue {
  @Prop({ type: String, default: '', required: true })
  planId!: string
  @Prop({ type: String, default: '', required: true })
  year!: string
  fileList: Object[] = [
    {
      name: '1.jpg',
    },
  ]
  PlanDetailData: any = {}
   dataLoading: boolean = true

  mounted() {
    this.getPlanDetail()
  }
  async getPlanDetail() {
    const [err, data] = await getYearPlanDetailById(this.planId)
    if (err) {
      this.dataLoading = false
      return
    }
    if (data) {
      this.PlanDetailData = data
      if (data.businessAttachmentList === null) {
        this.PlanDetailData.businessAttachmentList = []
      }
      this.dataLoading = false
    }
  }
  get userName() {
    return this.$store.state.user.name
  }
}
