


































import { Component, Vue, Prop } from 'vue-property-decorator'
import { getMonthPlanDetail } from '@/api/month'
import axios from 'axios'
import moment from 'moment'
import Commit from '@/components/comment/index.vue'
import ThumbsUp from '@/components/ThumbsUp/index.vue'
@Component({
  components: {
    Commit,
    ThumbsUp
  },
})
export default class MonthDetail extends Vue {
  @Prop({ type: String, default: '', required: true })
  planId!: string

  currentPlanId: string = ''
   dataLoading: boolean = true
  private formData = {
    title: '',
    content: '',
    planYear: new Date().getFullYear().toString(),
    planMonth: (new Date().getMonth() + 1).toString(),
    businessAttachmentList: [],
  }
  private planData: any = []
  //查询计划详情
  async getMonthPlanDetail(id: string) {
    console.log(id)
    const [err, data] = await getMonthPlanDetail(id)
     if (err) {
      this.dataLoading = false
      return
    }
    if (!err) {
      this.formData = data
      if (data.businessAttachmentList === null) {
        this.formData.businessAttachmentList = []
      }
      this.dataLoading = false
    }
  }
  // 得到年份选择器的值
  mounted() {
    this.getMonthPlanDetail(this.planId)
  }
}
