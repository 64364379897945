








import { Component, Vue } from 'vue-property-decorator'
import YearDetail from './components/YearDetail/index.vue'
import MonthDetail from './components/MonthDetail/index.vue'
import WeekDetail from './components/WeekDetail/index.vue'
import DailgDetail from './components/DailgDetail/index.vue'
@Component({
  components: {
    YearDetail,
    MonthDetail,
    WeekDetail,
    DailgDetail
  },
})
export default class Detail extends Vue {
  type: any = 1
  planId: any = ''
  year:any = ''
  mounted() {
    console.log(this.$route)
    if (this.$route.query) {
      this.type = this.$route.query.type
      this.planId = this.$route.query.planId
      this.year = this.$route.query.year
      console.log(this.type, this.planId,this.year)
    }
  }
}
